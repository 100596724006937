import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import classNames from "classnames";
import ReactDOM from "react-dom";
import { Button, Tooltip, Text } from "samespace-zen";
import { AnimatePresence, motion } from "framer-motion";

import ProfileEditor from "./ProfileEditor";
import { GET_USER_PROFILE } from "./queries";

import {
  IconOrganizationProfile,
  IconSignOut,
  IconProfileButton,
  IconSamespace,
  IconAnalytics,
  IconPulse,
  IconUserTeams,
  IconStudio,
  IconAutodialer,
  IconSettings,
  IconHelp,
  IconAdminSettings,
  IconStories,
  IconContacts,
  IconBilling,
  IconWave,
} from "./icons";
import {
  getActiveSpaceId,
  getAnalyticsLink,
  getOrgToken,
  getPrivilege,
  getSavedSession,
  getSpaceToken,
  isAdmin,
  logout,
  parseJwt,
  routeToActiveProductHome,
} from "../../utils/common";
import { useSession } from "../../context/session";
import { Avatar } from "../Avatar";
import {
  products as _products,
  CONNECT_ID,
  products,
  WORK_ID,
} from "../../utils/constants";
import { getCookie, removeCookie, setCookie } from "../../utils/cookies";
import { useQuery } from "@apollo/client";
import { IconArrow, IconCheck } from "../../utils/icons";

export const Header = () => {
  const [session] = useSession();
  const { activeProduct } = session;

  const profileWrapper = useRef();
  const productPopoverRef = useRef();
  const productPopoverTimeout = useRef();
  const history = useHistory();
  const location = useLocation();

  const [showProfileSettings, setShowProfileSettings] = useState(false);
  const [showProfilePopup, setShowProfilPopup] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showProductSwitcher, setShowProductSwitcher] = useState(false);
  const [navbarItems, setNavbarItems] = useState([]);
  const [products, setProducts] = useState([]);

  const onLogoClick = useCallback(() => {
    routeToActiveProductHome(history);
  }, [history]);

  const onProfileClick = useCallback(() => {
    setShowProfilPopup(!showProfilePopup);
  }, [showProfilePopup]);
  const profileSettingsCloseHandler = useCallback(() => {
    setShowProfileSettings(false);
  }, []);
  const profileSettingsShowHandler = useCallback(() => {
    setShowProfileSettings(true);
    setShowProfilPopup(false);
  }, []);

  const onProductClick = useCallback(() => {
    setShowProductSwitcher((prevState) => !prevState);
  }, []);

  const { data, loading } = useQuery(GET_USER_PROFILE, {
    skip: !getOrgToken(),
    context: {
      headers: {
        authorization: getOrgToken(),
      },
    },
  });

  const updateProducts = useCallback((product) => {
    console.log("updateProducts: ", product);
    const savedSession = getSavedSession();

    const { organizations, activeOrg } = savedSession;
    if (!product) {
      return;
    }

    if (
      !organizations[activeOrg].spaces ||
      organizations[activeOrg].spaces.length === 0
    ) {
      return;
    }
    const filteredProducts = _products?.filter((product) => {
      const space = organizations[activeOrg].spaces?.find(
        (sp) => sp.productName === product.id
      );
      return !!space;
    });

    filteredProducts.sort((a, b) => {
      if (a.id !== product && b.id === product) {
        return 1;
      }
      if (a.id === product && b.id !== product) {
        return -1;
      }
      return 0;
    });
    console.log("filteredProducts: ", filteredProducts);

    setProducts(
      filteredProducts.map((item) => {
        return {
          ...item,
          spaces: organizations[activeOrg]?.spaces?.filter(
            (sp) => sp.productName === item.id
          ),
        };
      })
    );
  }, []);

  useEffect(() => {
    console.log("useEffect activeProduct: ", activeProduct);
    updateProducts(activeProduct);
  }, [updateProducts, activeProduct]);

  useEffect(() => {
    const productPath = location.pathname.split("/")[1];
    const product = _products.find((pr) => pr.path === productPath);
    if (product) {
      setSelectedProduct(product);
    }
  }, [location.pathname]);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const showSidebar = searchParams.get("showSidebar");
    if (showSidebar) {
      setShowProfileSettings(true);
    }
  }, [location.search]);
  useEffect(() => {
    if (showProductSwitcher || showProfilePopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showProductSwitcher, showProfilePopup]);

  useEffect(() => {
    if (activeProduct === CONNECT_ID) {
      let spaceToken = getSpaceToken();
      spaceToken = parseJwt(spaceToken);
      const privilege = spaceToken.privilege || {};
      const isAdmin = privilege.admin;
      const isManager = privilege.manage;
      const isSupervisor = privilege.insights;
      const allowInsights = isAdmin || isSupervisor;
      const allowManage = isAdmin || isManager;
      const items = [
        {
          icon: IconAnalytics,
          title: "Analytics",
          tooltip: "Analytics",
          link: getAnalyticsLink(),
          show: true,
        },
        {
          icon: IconPulse,
          title: "Pulse",
          tooltip: "Pulse",
          link: "/connect/insights/pulse-v2",
          show: allowInsights,
        },
        {
          icon: IconContacts,
          title: "Contacts",
          tooltip: "Contacts",
          link: "/connect/contacts",
          show: allowInsights,
        },
        {
          icon: IconUserTeams,
          title: "Users + Teams",
          tooltip: "Users + Teams",
          link: "/connect/manage/users",
          show: allowManage,
        },
        {
          icon: IconStudio,
          title: "Studio",
          tooltip: "Studio",
          link: "/connect/studio",
          show: allowManage,
        },
        {
          icon: IconAutodialer,
          title: "Playbooks",
          tooltip: "Playbooks",
          link: "/connect/playbooks",
          show: allowManage,
        },
      ];
      setNavbarItems(items);
    } else if (activeProduct === WORK_ID) {
      const items = [
        {
          icon: IconAnalytics,
          title: "Analytics",
          tooltip: "Analytics",
          link: "/work/insights/summary",
          show: true,
        },
        {
          icon: IconWave,
          title: "Wave",
          tooltip: "Wave",
          link: "/work/wave",
          show: true,
        },
        {
          icon: IconStories,
          title: "Stories",
          tooltip: "Stories",
          link: "/work/stories",
          show: true,
        },
      ];
      setNavbarItems(items);
    } else {
      setNavbarItems([]);
    }
  }, [activeProduct]);

  const productPath = location.pathname.split("/")[1];

  const onProductHover = useCallback(() => {
    // console.log("onProductHover: ");
    clearTimeout(productPopoverTimeout.current);
    setShowProductSwitcher(true);
  }, []);

  const onProductHoverOut = useCallback((e) => {
    // console.log("onProductHoverOut: ");
    productPopoverTimeout.current = setTimeout(() => {
      setShowProductSwitcher(false);
    }, 50);
  }, []);

  const onSelectProduct = useCallback(
    (id, space) => {
      console.log("onSelectProduct id: ", id, " space: ", +space);
      updateProducts(id);
      setTimeout(() => {
        if (space) {
          setCookie("activeSpace", space);
          history.replace("/");
        }
      }, 0);
    },
    [updateProducts, history]
  );

  console.log(
    "products: ",
    products.map((product) => product.id)
  );

  return (
    <>
      <ProfileEditor
        showProfile={showProfileSettings}
        onClose={profileSettingsCloseHandler}
      />
      {showProfilePopup && (
        <div className="h-[calc(100vh-56px)] w-screen top-[56px] bg-black/50 backdrop-blur-sm fixed z-[10001] transition-all duration-200" />
      )}
      <nav className="z-[10001] bg-skin-primary fixed top-0 w-full flex items-center justify-between h-[56px] px-[16px]">
        <div className="flex items-center space-x-[16px]">
          <div className="cursor-pointer" onClick={onLogoClick}>
            {IconSamespace}
          </div>
          {selectedProduct && (
            <div className="relative">
              {(products.length > 1 ||
                products?.filter((product) => product.id === selectedProduct.id)
                  ?.spaces?.length > 1) && (
                <>
                  <div
                    className="relative flex h-[36px]"
                    onMouseOver={onProductHover}
                    onMouseOut={onProductHoverOut}
                  >
                    <Text
                      label={selectedProduct.title}
                      color="white"
                      className="cursor-pointer"
                      size="xs"
                      case="uppercase"
                      weight="semibold"
                      iconPlacement="right"
                      icon={
                        <svg
                          width="6"
                          height="10"
                          viewBox="0 0 6 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 3.5L3 1.5L5 3.5"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1 6.5L3 8.5L5 6.5"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      }
                      onClick={onProductClick}
                    />
                    <AnimatePresence>
                      {showProductSwitcher && (
                        <ProductPopover
                          containerRef={productPopoverRef}
                          open={showProductSwitcher}
                          activeProduct={activeProduct}
                          products={products}
                          selectedProduct={selectedProduct}
                          onSelectProduct={onSelectProduct}
                        />
                      )}
                    </AnimatePresence>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <div className="absolute left-1/2 -translate-x-1/2 flex space-x-[32px]">
          {navbarItems
            .filter((item) => item.show)
            .map((item) => {
              return <HeaderButton {...item} />;
            })}
        </div>
        <div className="flex items-center space-x-[8px]">
          {((productPath === "connect" && getPrivilege().admin) ||
            productPath === "work") && (
            <HeaderButton
              icon={IconSettings}
              title="Settings"
              link={`/${productPath}/settings`}
            />
          )}
          <HeaderButton
            icon={IconHelp}
            link={"https://support.samespace.com"}
            newTab
          />
          <div className="relative" ref={profileWrapper}>
            <Button
              size="small"
              appearance="ghost"
              onClick={onProfileClick}
              accent="contrast"
              shape="rounded"
              icon={
                <>
                  {loading || !data ? (
                    <div className="h-[32px] w-[32px] opacity-20 bg-black rounded-full"></div>
                  ) : (
                    <>
                      {data?.getUser?.photoUrl ? (
                        <Avatar url={data?.getUser?.photoUrl} size="small" />
                      ) : (
                        <Avatar size="small" />
                      )}
                    </>
                  )}
                </>
              }
            />
            <ProfilePopover
              showProfile={profileSettingsShowHandler}
              show={showProfilePopup}
              onClose={onProfileClick}
              profileWrapperRef={profileWrapper.current}
              selectedProduct={selectedProduct}
            />
          </div>
        </div>
      </nav>
    </>
  );
};

const HeaderButton = ({ icon, title, tooltip, link, newTab }) => {
  const history = useHistory();

  const onNavBarItemClick = useCallback(
    (link) => {
      if (link && !newTab) {
        return history.push(link);
      } else window.open(link, "_blank");
    },
    [history, newTab]
  );
  const productPath = window.location.pathname.split("/")[1];

  return (
    <Tooltip
      key={title}
      title={tooltip}
      arrow={false}
      position="bottom"
      delay={500}
    >
      <div
        key={`${productPath}-${title}`}
        className={classNames(
          "rounded-full h-[40px] w-[40px] flex items-center justify-center cursor-pointer py-3 transition-colors hover:bg-black/20",
          {
            "bg-black/20": window.location.href.includes(link),
          }
        )}
        onClick={onNavBarItemClick.bind(null, link)}
      >
        {icon}
      </div>
    </Tooltip>
  );
};

const ProfilePopover = ({
  show,
  onClose,
  profileWrapperRef,
  showProfile,
  selectedProduct,
}) => {
  const popoverRef = useRef();
  const organizations = useRef(getCookie("organizations") || {});
  const activeOrg = useRef(getCookie("activeOrg"));

  useEffect(() => {
    const handleMouseUp = (event) => {
      if (
        show &&
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        !profileWrapperRef.contains(event.target)
      ) {
        onClose && onClose();
      }
    };

    const handleKeyPress = (e) => {
      if (show && e.keyCode === 27) {
        onClose && onClose();
      }
    };

    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("keyup", handleKeyPress);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("keyup", handleKeyPress);
    };
  }, [show, onClose, profileWrapperRef]);

  const onSignOutClick = useCallback(() => {
    logout();
  }, []);

  const onOrganizationClick = useCallback(
    (orgId) => {
      const currentOrgId = getCookie("activeOrg");
      onClose && onClose();
      if (currentOrgId !== orgId) {
        const cookies = [
          "spaceToken",
          "activeSpace",
          "spaceUserInfo",
          "orgToken",
          "activeOrg",
        ];
        cookies.forEach((cookie) => {
          removeCookie(cookie);
        });
        setCookie("activeOrg", orgId);
        const savedSession = getSavedSession();
        const { organizations } = savedSession;
        if (
          organizations[orgId].spaces &&
          organizations[orgId].spaces.length > 0
        ) {
          const spaceData = organizations[orgId].spaces.find(
            (space) => space.productName === selectedProduct.id
          );
          if (spaceData) {
            window.location.href = `/${selectedProduct.path}`;
          } else {
            const product = products.find((product) => {
              const space = organizations[orgId].spaces.find(
                (sp) => sp.productName === product.id
              );
              return !!space;
            });
            if (product) {
              window.location.href = `/${product.path}`;
            } else {
              window.location.href = "/work";
            }
          }
        } else {
          window.location.href = "/work";
        }
      }
    },
    [onClose, selectedProduct]
  );
  const onBillingClick = useCallback(
    (e) => {
      e.stopPropagation();
      onClose();
      setTimeout(() => {
        window.open("/admin/people", "_blank");
      });
    },
    [onClose]
  );

  return (
    <>
      {ReactDOM.createPortal(
        <article
          ref={popoverRef}
          className={classNames(
            "z-[10001] rounded-lg mt-6 fixed top-10 right-4 shadow-popover bg-skin-base w-[280px] origin-top-right transition-all",
            {
              "opacity-0 scale-50 pointer-events-none": !show,
              "opacity-1 scale-100 pointer-events-initial": show,
            }
          )}
        >
          <div className="flex flex-col">
            <div
              className="mx-3 h-[44px] mt-3 flex items-center hover:bg-gray-100 rounded cursor-pointer"
              onClick={showProfile}
            >
              <Text
                icon={
                  <div className="ml-1 w-[24px] h-[24px]">
                    {IconProfileButton}
                  </div>
                }
                label="Profile"
              />
            </div>

            {Object.keys(organizations.current).length === 1 && isAdmin() && (
              <div
                className="mx-3 h-[44px] mt-3 flex items-center hover:bg-gray-100 rounded cursor-pointer"
                onClick={onBillingClick}
              >
                <Text
                  icon={
                    <div className="ml-1 w-[24px] h-[24px]">{IconBilling}</div>
                  }
                  label="Account & Billing"
                />
              </div>
            )}
            {Object.keys(organizations.current).length > 1 && (
              <hr className="divider-opacity dark:divider-opacity-dark my-[6px]" />
            )}
            {Object.keys(organizations.current).length > 1 &&
              Object.keys(organizations.current).map((item, index) => {
                return (
                  <div
                    className={classNames(
                      "flex flex-col mx-3 rounded hover:bg-skin-base-100 cursor-pointer py-3 justify-center group",
                      {
                        "bg-skin-base-100": item === activeOrg.current,
                        "mt-0.5": index > 0,
                      }
                    )}
                    onClick={onOrganizationClick.bind(null, item)}
                  >
                    <div className="flex items-center gap-x-2 justify-between">
                      <Text
                        truncate
                        icon={
                          <div className="ml-1 w-[24px] h-[24px]">
                            {IconOrganizationProfile}
                          </div>
                        }
                        label={
                          organizations.current[item].orgLabel ||
                          organizations.current[item].orgName
                        }
                      />
                      {activeOrg.current === item && isAdmin() && (
                        <div
                          onClick={onBillingClick}
                          className="group-hover:opacity-100 opacity-0 hover:bg-gray-200 rounded-full transition-all duration-200 p-1 flex items-center justify-center mr-2"
                        >
                          {IconAdminSettings}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}

            <hr className="divider-opacity dark:divider-opacity-dark my-[6px]" />
            <div
              onClick={onSignOutClick}
              className="mx-3 mb-[14px] h-[44px] pl-[2px] flex items-center hover:bg-red-100 rounded cursor-pointer"
            >
              <Text
                label="Log Out"
                icon={
                  <div className="ml-1 w-[24px] h-[24px]">{IconSignOut}</div>
                }
              />
            </div>
          </div>
        </article>,
        document.body
      )}
    </>
  );
};

const ProductPopover = ({
  products,
  onSelectProduct,
  selectedProduct,
  containerRef,
}) => {
  const [showSpaces, setShowSpaces] = useState(null);

  return (
    <motion.div
      ref={containerRef}
      className="absolute -top-1 -left-3 w-[128px] p-1 rounded bg-skin-primary"
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: "auto", opacity: 1 }}
      exit={{ height: 0, opacity: 0 }}
      transition={{
        easings: [0.18, 0.5, 0.28, 0.8],
        duration: 0.2,
      }}
    >
      <section className="z-10 relative flex flex-col">
        {products.map((product, index) => (
          <motion.div
            key={product.id}
            layout
            transition={{
              layout: { duration: 0 },
            }}
            className={classNames(
              "relative group pointer rounded cursor-pointer transition px-[8px] py-[10px]",
              {
                "mt-[2px]": index > 0,
              }
            )}
            onClick={() => {
              console.log("onclick product : ", product);
              if (product.spaces.length > 1) {
                if (product.id === showSpaces) {
                  setShowSpaces(null);
                } else {
                  setShowSpaces(product.id);
                }
              } else {
                if (product.id === selectedProduct.id) {
                } else {
                  onSelectProduct.bind(
                    null,
                    product.id,
                    product.spaces[0] && product.spaces[0].spaceId
                  )();
                }
              }
            }}
          >
            <div className="relative z-10 flex items-center justify-between">
              <Text
                label={product.title}
                truncate
                weight="semibold"
                size="xs"
                case="uppercase"
                color="white"
              />
              {product.spaces.length > 1 && IconArrow}
            </div>

            <div
              className={classNames(
                "absolute rounded inset-0 pointer-events-none transition-all",
                {
                  "bg-black/20": product.id === selectedProduct.id,
                  "group-hover:bg-black/10": product.id !== selectedProduct.id,
                }
              )}
            />
            <AnimatePresence>
              {product.id === showSpaces && (
                <motion.div
                  initial={{ scale: 0.9, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.9, opacity: 0 }}
                  transition={{
                    easings: [0.18, 0.5, 0.28, 0.8],
                    duration: 0.2,
                  }}
                  className="origin-top-left absolute top-0 left-full bg-skin-primary ml-[2px] rounded  ring-1 ring-inset ring-black/40"
                >
                  <div className="relative z-10 w-[128px] p-[4px]">
                    {product.spaces.map((space) => {
                      return (
                        <div
                          className="relative px-[4px] py-[9px]"
                          onClick={() => {
                            if (getActiveSpaceId() !== String(space.spaceId)) {
                              onSelectProduct.bind(
                                null,
                                product.id,
                                space.spaceId
                              )();
                            } else {
                              setShowSpaces(null);
                            }
                          }}
                        >
                          <div className="flex items-center justify-between cursor-pointer relative z-10">
                            <Text
                              iconSpacing="normal"
                              size="sm"
                              weight="medium"
                              color="white"
                              className={classNames({
                                "opacity-60":
                                  getActiveSpaceId() !== String(space.spaceId),
                              })}
                              label={space.spaceName || space.spaceId}
                            />
                            <div
                              className={classNames({
                                "opacity-0":
                                  getActiveSpaceId() !== String(space.spaceId),
                              })}
                            >
                              {IconCheck}
                            </div>
                          </div>
                          <div
                            className={classNames(
                              "absolute inset-0 pointer-events-none rounded"
                            )}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="absolute inset-0 bg-black/20 rounded pointer-events-none" />
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        ))}
      </section>
      <div className="absolute inset-0 bg-black/20 rounded pointer-events-none" />
    </motion.div>
  );
};
